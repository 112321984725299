/** @format */

import React from "react";
import Layout from "../components/Layout";
import DetalleRecetas from './recetas/DetalleReceta';
import Recetas from './recetas/Recetas';
import { Router as CSRourter } from "@reach/router";

export default function SobreNosotros() {
  return (
    <Layout>
      <CSRourter>
        <DetalleRecetas path="/recetas/detalle-receta/:id" />
        <Recetas path="/recetas" />
      </CSRourter>
    </Layout>
  );
}
